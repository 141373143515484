import * as React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../../components/layout'


const BlogPage = ({ data }) => {
    return (
        <Layout pageTitle="Posts">
            {
                data.allMdx.nodes.map((node) => (
                    <article key={node.id}>
                        <h2>
                            <Link to={`${node.frontmatter.basename}`}>
                                {node.frontmatter.title}
                            </Link>
                        </h2>
                        <p>
                            Posted: {node.frontmatter.date}
                        </p>
                    </article>
                )
                )
            }
        </Layout>
    )
}

//export const query = graphql`
//query{
//
//allMdx(sort: {fields: frontmatter___date, order: DESC}) {
//    nodes {
//      frontmatter {
//        date(formatString: "MM/DD/YYYY")
//        title
//      }
//      id
//      slug
//    }
//  }
//}
//`
export const query = graphql`
query{
      allMdx(
        sort: {fields: frontmatter___date, order: DESC}
        filter: {frontmatter: {status: {eq: "Publish"}}}
      ) {
          nodes {
            frontmatter {
              basename
              title
              date(formatString: "MMMM D, YYYY")
              status
            }
          }
      }
}
`


export default BlogPage